import { Categories } from '@segment/analytics-consent-tools';

type ActiveGroupIds = string[];

const normalizeActiveGroupIds = (oneTrustActiveGroups: string): ActiveGroupIds => {
  return oneTrustActiveGroups.trim().split(',').filter(Boolean);
};

type GroupInfoDto = {
  CustomGroupId: string;
};

type OtConsentChangedEvent = CustomEvent<ActiveGroupIds>;

export enum OtConsentModel {
  optIn = 'opt-in',
  optOut = 'opt-out',
  implicit = 'implied consent'
}

export interface OneTrustDomainData {
  ShowAlertNotice: boolean;
  Groups: GroupInfoDto[];
  ConsentModel: {
    Name: OtConsentModel;
  };
}

export interface OneTrustGlobal {
  GetDomainData: () => OneTrustDomainData;
  OnConsentChanged: (cb: (event: OtConsentChangedEvent) => void) => void;
  IsAlertBoxClosed: () => boolean;
}

export const getOneTrustGlobal = (): OneTrustGlobal | undefined => {
  const oneTrust = (window as any).OneTrust;
  if (!oneTrust) return undefined;
  if (
    typeof oneTrust === 'object' &&
    'OnConsentChanged' in oneTrust &&
    'IsAlertBoxClosed' in oneTrust &&
    'GetDomainData' in oneTrust
  ) {
    return oneTrust;
  }
};

export const getOneTrustActiveGroups = (): string | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  const groups = (window as any).OnetrustActiveGroups;
  if (!groups) return undefined;
  return groups;
};

export const getNormalizedActiveGroupIds = (oneTrustActiveGroups = getOneTrustActiveGroups()): ActiveGroupIds => {
  if (!oneTrustActiveGroups) {
    return [];
  }
  return normalizeActiveGroupIds(oneTrustActiveGroups || '');
};

export type GroupInfo = {
  groupId: string;
};

const normalizeGroupInfo = (groupInfo: GroupInfoDto): GroupInfo => ({
  groupId: groupInfo.CustomGroupId.trim()
});

/**
 * get *all* groups / categories, not just active ones
 */
export const getAllGroups = (): GroupInfo[] => {
  const oneTrustGlobal = getOneTrustGlobal();
  if (!oneTrustGlobal) return [];
  return oneTrustGlobal.GetDomainData().Groups.map(normalizeGroupInfo);
};

export const getNormalizedCategories = (activeGroupIds = getNormalizedActiveGroupIds()): Categories => {
  return getAllGroups().reduce<Categories>((acc, group) => {
    return {
      ...acc,
      [group.groupId]: activeGroupIds.includes(group.groupId)
    };
  }, {});
};
