import { GetCurrentApplicationConfiguration, Method } from '@gf/cross-platform-lib/utils';
import { privateSafeFetch, safeFetch } from '../utils';
import { User } from '@gf/cross-platform-lib/interfaces';

const appConfig = GetCurrentApplicationConfiguration();

export const updateUserInfo = async (email: string, queryParams: { [key: string]: any }) => {
  const url = appConfig.api.users.getUrlForMethodAndId!(Method.PATCH, email);

  const headers = new Headers({
    'Content-Type': 'application/json'
  });

  return privateSafeFetch(url!, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(queryParams)
  });
};

export const getUserByEmailToken = async (emailToken: string) => {
  const url = appConfig.api.users.getUrlForMethodAndId!(Method.GET, `?email_token=${emailToken}`);
  return safeFetch<User>(url!);
};

export const getUserByEmailString = async (emailString: string) => {
  const url = appConfig.api.users.getUrlForMethodAndId!(Method.GET, `${emailString}`);
  return privateSafeFetch<User>(url!);
};

export const resetPassword = async (emailString: string) => {
  const url = `${appConfig.api.userService.baseUrl}/api/reset-password`;
  return safeFetch<{ messageId: string }>(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: emailString
      })
    },
    { redirect404: false }
  );
};
