import { QueryParams } from '@gf/cross-platform-lib/interfaces';

export interface Dictionary<T> {
  [key: string]: T;
}

export enum Method {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  OPTIONS = 'OPTIONS',
  DELETE = 'DELETE'
}

export interface EndpointSpec {
  baseUrl?: string; // override parent `api` object baseUrl
  key?: string; // override parent API key
  getUrlForMethodAndId?: (m: Method, id: string, queryParams?: QueryParams) => string | undefined;
  getUrlForMethod?: (m: Method) => string | undefined;
  getUrlForMethodAndEmail?: (m: Method, queryParams?: QueryParams) => string | undefined;
  getUrl?: (p?: string) => string | undefined;
  getSearchUrl?: (queryParams?: QueryParams) => string | undefined;
  getSearchUrlForId?: (id: string, queryParams?: QueryParams) => string | undefined;
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  webAppId: string;
  measurementId: string;
}

export interface PaymentConfig {
  stripe: {
    publicKey: string;
    merchantIdentifier: string;
  };
  googlePay: {
    env: 'TEST' | 'PRODUCTION';
    merchantId: string;
    merchantName: string;
  };
}

export interface ITrackingSegmentPlatformConfig {
  writeKey: string;
}
export interface ITrackingSegmentConfig {
  cdn: string;
  maxBatchSize: number;
  flushAt: number;
  flushInterval: number;
  web: ITrackingSegmentPlatformConfig;
  ios: ITrackingSegmentPlatformConfig;
  android: ITrackingSegmentPlatformConfig;
}

export interface ITrackingConfig {
  segment: ITrackingSegmentConfig;
}

export interface Configuration {
  origin: {
    url?: string;
    urlWeb?: string;
  };
  api: {
    baseUrl?: string;
    key?: string; // API key
    v1: EndpointSpec;
    fanGateway: EndpointSpec;
    forms: EndpointSpec;
    events: EndpointSpec;
    eventsV1: EndpointSpec;
    encodedEvents: EndpointSpec;
    mobilePass: EndpointSpec;
    notifications: EndpointSpec;
    orders: EndpointSpec;
    orderservice: EndpointSpec;
    ordersV2: EndpointSpec;
    promotions: EndpointSpec;
    seasonRenewalFansEncoded: EndpointSpec;
    seasons: EndpointSpec;
    schools: EndpointSpec;
    search: EndpointSpec;
    encoded: EndpointSpec;
    users: EndpointSpec;
    sponsor: EndpointSpec;
    sponsorV2: EndpointSpec;
    encrypt: EndpointSpec;
    seatsIo: {
      releaseSeats: EndpointSpec;
      holdToken: EndpointSpec;
    };
    team: EndpointSpec;
    userService: EndpointSpec;
    taxService: EndpointSpec;
  };
  settings: {
    searchInputTimeout: number;
    firebase: FirebaseConfig;
    currencyFormatting: {
      [name: string]: { locale: string; currency: string; style: string; trailingZeroDisplay?: string };
    };
    payment: PaymentConfig;
    tracking: ITrackingConfig;
    seatsio: { publicWorkspaceKey: string };
  };
  cdn: {
    uploadUrl: string;
  };
  assets: {
    host: string;
    pdf: {
      downloadUrl: string;
    };
  };
  newrelic: {
    enabled: boolean;
    web_app_name?: string;
    license_key?: string;
    ios_app_token?: string;
    android_app_token?: string;
    mobile_agent_log_level: 'ERROR' | 'WARNING' | 'INFO' | 'VERBOSE' | 'AUDIT';
    infrastructure_agent_log_level: 'error' | 'warn' | 'info' | 'debug' | 'trace';
  };
  recaptcha: {
    web_key: string;
  };
  launchdarkly: {
    mobileKey: string;
    clientId: string;
    debugMode: boolean;
  };
  yellowai: {
    chatbotId: string;
    host: string;
  };
  appVersion: string;
  latestCommit: string;
  gam_network_id?: string;
  onetrust: {
    web: {
      domainIdentifier: string;
    };
    ios: {
      domainIdentifier: string;
    };
    android: {
      domainIdentifier: string;
    };
  };
}

export function CreateEmptyAppConfig(): Configuration {
  return {
    origin: {
      url: undefined
    },
    recaptcha: {
      web_key: ''
    },
    yellowai: {
      chatbotId: '',
      host: ''
    },
    api: {
      baseUrl: undefined,
      key: undefined,
      v1: {
        baseUrl: undefined,
        key: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined,
        getSearchUrl: undefined
      },
      events: {
        baseUrl: undefined,
        key: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined,
        getSearchUrl: undefined
      },
      eventsV1: {
        baseUrl: undefined,
        key: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined,
        getSearchUrl: undefined
      },
      encodedEvents: {
        baseUrl: undefined,
        key: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined,
        getSearchUrl: undefined
      },
      fanGateway: {
        baseUrl: undefined
      },
      forms: {
        baseUrl: undefined,
        getUrlForMethodAndId: undefined
      },
      mobilePass: {
        baseUrl: undefined,
        getUrl: undefined,
        getUrlForMethodAndEmail: undefined
      },
      notifications: {
        baseUrl: undefined,
        getUrl: undefined
      },
      orders: {
        baseUrl: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined
      },
      orderservice: {
        baseUrl: undefined,
        getUrlForMethodAndId: undefined
      },
      ordersV2: {
        baseUrl: undefined,
        getUrl: undefined
      },
      promotions: {
        baseUrl: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined
      },
      seasons: {
        baseUrl: undefined,
        key: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined
      },
      seasonRenewalFansEncoded: {
        baseUrl: undefined,
        getUrlForMethod: undefined,
        getUrlForMethodAndId: undefined
      },
      schools: {
        baseUrl: undefined,
        key: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined
      },
      search: {
        baseUrl: undefined,
        key: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined
      },
      encoded: {
        baseUrl: undefined,
        key: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined
      },
      users: {
        baseUrl: undefined,
        key: undefined,
        getUrl: undefined,
        getUrlForMethodAndId: undefined
      },
      sponsor: {
        baseUrl: undefined,
        getUrl: undefined
      },
      sponsorV2: {
        baseUrl: undefined,
        getUrlForMethod: undefined
      },
      encrypt: {
        baseUrl: undefined,
        getUrl: undefined
      },
      seatsIo: {
        releaseSeats: {
          baseUrl: undefined,
          getUrl: undefined
        },
        holdToken: {
          baseUrl: undefined,
          getUrl: undefined
        }
      },
      team: {
        baseUrl: undefined,
        getUrl: undefined
      },
      userService: {
        baseUrl: undefined,
        getUrl: undefined
      },
      taxService: {
        baseUrl: undefined,
        getUrl: undefined
      }
    },
    settings: {
      searchInputTimeout: 350,
      firebase: {
        apiKey: '',
        authDomain: '',
        databaseURL: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        webAppId: '',
        measurementId: ''
      },
      currencyFormatting: {},
      payment: {
        stripe: {
          publicKey: '',
          merchantIdentifier: ''
        },
        googlePay: {
          env: 'TEST',
          merchantId: '',
          merchantName: ''
        }
      },
      tracking: {
        segment: {
          flushAt: 1,
          flushInterval: 1,
          maxBatchSize: 1,
          web: {
            writeKey: ''
          },
          android: {
            writeKey: ''
          },
          ios: {
            writeKey: ''
          },
          cdn: ''
        }
      },
      seatsio: {
        publicWorkspaceKey: ''
      }
    },
    cdn: { uploadUrl: '' },
    assets: {
      host: 'http://localhost:3000',
      pdf: {
        downloadUrl: ''
      }
    },
    newrelic: {
      enabled: false,
      web_app_name: undefined,
      license_key: undefined,
      ios_app_token: undefined,
      android_app_token: undefined,
      mobile_agent_log_level: 'INFO',
      infrastructure_agent_log_level: 'info'
    },
    launchdarkly: {
      mobileKey: '',
      clientId: '',
      debugMode: false
    },
    appVersion: '',
    latestCommit: '',
    onetrust: {
      web: {
        domainIdentifier: ''
      },
      ios: {
        domainIdentifier: ''
      },
      android: {
        domainIdentifier: ''
      }
    }
  };
}
