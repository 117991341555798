import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './ErrorTaxModal.styles';
import { Carbonicons, StyledButton } from '@gf/cross-platform-lib/components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { useModal } from '../../ModalProvider';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Container = styled.View`
  ${styles.container};
`;

const Title = styled.Text`
  ${styles.title};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const BtnContainer = styled.View<{ isCenter: boolean }>`
  ${styles.container};
  justify-content: center;
`;

const ContentWrapper = styled.View`
  ${styles.contentWrapper}
`;

const ContentContainer = styled.View`
  ${styles.contentContainer}
`;

const CloseBtnContainer = styled.TouchableOpacity`
  ${styles.closeBtnContainer}
`;

export const ErrorTaxModal = () => {
  const { isMobile } = useMediaQuery();
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const {
    onConfirm = () => {},
    title = 'Tax amount adjusted',
    subTitle = 'The tax amount has been updated. Please review your order before proceeding.'
  } = modalProps || {};
  return (
    <ModalWrapper height={subTitle.length > 80 ? 240 : 210} width={isMobile ? 320 : 400}>
      <CloseBtnContainer
        onPress={() => {
          onConfirm();
          hideModal();
        }}
      >
        <Carbonicons name='close' size={22} color='#161616' />
      </CloseBtnContainer>
      <ContentWrapper>
        <ContentContainer>
          <Container>
            <Title>{title}</Title>
          </Container>
          <SubTitle isMobile={isMobile}>{subTitle}</SubTitle>
        </ContentContainer>
      </ContentWrapper>
      <BtnContainer>
        <StyledButton
          buttonType='primary'
          title='OK'
          accessibilityLabel='OK'
          onPress={() => {
            onConfirm();
            hideModal();
          }}
          width='128px'
          testID='confirm-button'
        />
      </BtnContainer>
    </ModalWrapper>
  );
};
