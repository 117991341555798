import { GetCurrentApplicationConfiguration, Method } from '@gf/cross-platform-lib/utils';
import { User } from '@gf/cross-platform-lib/interfaces/User';
import { privateSafeFetch } from '@gf/cross-platform-lib/modules/AcquisitionV2/utils';

const appConfig = GetCurrentApplicationConfiguration();
export const getUserServiceInfo = async (email: string) => {
  const url = `${appConfig.api.userService.getUrlForMethod!(Method.POST)}`;
  const body = { email: email };
  return await privateSafeFetch<User>(url, { method: 'POST', body: JSON.stringify(body) });
};

export const updateUserServiceInfo = async (email: string, queryParams: { [key: string]: any }) => {
  const url = `${appConfig.api.userService.getUrlForMethod!(Method.PATCH)}`;
  const body = {
    user: {
      email: email,
      ...queryParams
    }
  };
  return await privateSafeFetch(url, { method: 'PATCH', body: JSON.stringify(body) });
};
