import Pluralize from 'pluralize';

export const getPluralTitleText = (total: number, text: string): string => {
  return `${total} ${Pluralize(text, total)}`;
};

import { useEffect, useState } from 'react';

const usePluralTitleText = (total: number, text: string) => {
  const [pluralTitleText, setPluralTitleText] = useState<string>('');

  useEffect(() => {
    setPluralTitleText(getPluralTitleText(total, text));
  }, [total, text]);

  return { pluralTitleText, setPluralTitleText };
};

export { usePluralTitleText };
