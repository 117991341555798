import { CreateEmptyAppConfig, Method } from '../ConfigTypes';
import { QueryParams } from '@gf/cross-platform-lib/interfaces';
import { parseQueryParamsToString } from '../parseQueryParams';
import { envConfig } from './env';

let config = CreateEmptyAppConfig();

config = {
  ...config,
  ...envConfig.config,
  appVersion: envConfig.appVersion || '',
  latestCommit: envConfig.latestCommit || ''
};

config.api.search.getUrl = q =>
  q ? `${config.api.search.baseUrl || config.api.baseUrl}/schools/search?q=${encodeURIComponent(q)}` : undefined;

config.api.search.getSearchUrl = q =>
  q ? `${config.api.search.baseUrl || config.api.baseUrl}/schools/search${parseQueryParamsToString(q)}` : undefined;

config.api.events.getUrl = q =>
  q ? `${config.api.events.baseUrl || config.api.baseUrl}/events/?accounts=${encodeURIComponent(q)}` : undefined;

config.api.events.getUrlForMethodAndId = (m, id) =>
  m === Method.GET ? `${config.api.events.baseUrl || config.api.baseUrl}/events/${id}` : undefined;

/**
 * @deprecated
 * @param m
 * @param id
 * @param q
 */
config.api.events.getSearchUrl = (q?: QueryParams) =>
  q ? `${config.api.events.baseUrl || config.api.baseUrl}/events/search${parseQueryParamsToString(q)}` : undefined;

config.api.eventsV1.getUrlForMethodAndId = (m, id) =>
  m === Method.GET ? `${config.api.eventsV1.baseUrl}/events/${id}` : undefined;

config.api.forms.getUrlForMethodAndId = (m, id) =>
  m === Method.GET
    ? `${config.api.forms.baseUrl || config.api.baseUrl}/form-responses/?page=0&purchased-ticket=${id}&size=10`
    : undefined;

config.api.seasons.getUrlForMethodAndId = (m, id) =>
  m === Method.GET ? `${config.api.seasons.baseUrl}/seasons/${id}?showHidden=true` : undefined;
config.api.seasons.getSearchUrl = (q?: QueryParams) =>
  q ? `${config.api.seasons.baseUrl}/seasons/search${parseQueryParamsToString(q)}` : undefined;

/**
 * @deprecated
 * @param m
 * @param id
 * @param q
 */
config.api.schools.getUrlForMethodAndId = (m, id, q) =>
  m === Method.GET
    ? `${config.api.schools.baseUrl || config.api.baseUrl}/schools/${id}${parseQueryParamsToString(q)}`
    : undefined;

config.api.encoded.getUrlForMethodAndId = (m, uniqueKey) =>
  m === Method.GET
    ? `${
        config.api.encoded.baseUrl || config.api.baseUrl
      }/events/encoded/${uniqueKey}?expand=account&expand=account-host&expand=account-opponent&expand=form`
    : undefined;

config.api.forms.getUrlForMethodAndId = (m, id) =>
  m === Method.GET
    ? `${config.api.forms.baseUrl || config.api.baseUrl}/form-responses/?page=0&purchased-ticket=${id}&size=10`
    : undefined;

config.api.users.getUrlForMethodAndId = (m, email) =>
  m === Method.PUT || m === Method.GET || m === Method.PATCH
    ? `${config.api.users.baseUrl || config.api.baseUrl}/users/${email}`
    : m === Method.POST
    ? `${config.api.users.baseUrl || config.api.baseUrl}/users/sign-up`
    : undefined;

config.api.notifications.getUrl = () =>
  `${config.api.notifications.baseUrl || config.api.baseUrl}/orders/notifications`;

config.api.orders.getUrlForMethodAndId = (m, accessToken) =>
  m === Method.PATCH
    ? `${config.api.orders.baseUrl || config.api.baseUrl}/orders/tickets/${accessToken}`
    : m === Method.GET
    ? `${
        config.api.orders.baseUrl || config.api.baseUrl
      }/orders?access-token=${accessToken}&expand=event,event.activity,promotion-discount,form-responses-order,promotion`
    : undefined;

config.api.orderservice.getUrlForMethodAndId = (m, accessToken) =>
  m === Method.PATCH ? `${config.api.orderservice.baseUrl}/orderservice/orders/tickets/${accessToken}` : undefined;

config.api.ordersV2.getUrl = m =>
  m === Method.PUT ? `${config.api.ordersV2.baseUrl || config.api.baseUrl}` : undefined;

config.api.promotions.getUrlForMethodAndId = (m, id) =>
  m === Method.POST
    ? `${config.api.promotions.baseUrl || config.api.baseUrl}/promotion-locks/`
    : m === Method.PATCH
    ? `${config.api.promotions.baseUrl || config.api.baseUrl}/promotion-locks/${id}`
    : m === Method.DELETE
    ? `${config.api.promotions.baseUrl || config.api.baseUrl}/promotion-locks/${id}`
    : m === Method.GET
    ? `${config.api.promotions.baseUrl || config.api.baseUrl}/promotion-locks/${id}`
    : undefined;

config.api.sponsor.getUrl = m =>
  m === Method.POST ? `${config.api.sponsor.baseUrl}/sponsors/slots/search` : undefined;

config.api.sponsorV2.getUrlForMethod = m =>
  m === Method.GET ? `${config.api.sponsorV2.baseUrl}/v1/sponsorships` : undefined;

config.api.seasonRenewalFansEncoded.getUrlForMethodAndId = (m, p) =>
  m === Method.GET ? `${config.api.seasonRenewalFansEncoded.baseUrl}/encoded/${p}` : undefined;
config.api.seasonRenewalFansEncoded.getUrlForMethod = m =>
  m === Method.POST ? `${config.api.seasonRenewalFansEncoded.baseUrl}/status` : undefined;

config.api.encrypt.getUrlForMethod = m => (m === Method.POST ? `${config.api.fanGateway.baseUrl}/encrypt` : undefined);

config.api.seatsIo = {
  releaseSeats: {
    baseUrl: `${config.api.fanGateway.baseUrl}/seatsio/releaseSeats`,
    getUrlForMethod: m => (m === Method.POST ? `${config.api.fanGateway.baseUrl}/seatsio/releaseSeats` : undefined)
  },
  holdToken: {
    baseUrl: `${config.api.fanGateway.baseUrl}/seatsio/holdToken`,
    getUrlForMethod: m =>
      m === Method.GET ? `${config.api.fanGateway.baseUrl}/seatsio/holdToken/{tokenId}` : undefined
  }
};

config.api.team.getUrl = m => (m === Method.GET ? `${config.api.team.baseUrl}` : undefined);

config.api.userService.getUrlForMethod = m =>
  m === Method.POST
    ? `${config.api.userService.baseUrl}/api/user/search`
    : m === Method.PATCH
    ? `${config.api.userService.baseUrl}/api/user`
    : undefined;

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;
const DAYS = 24 * HOURS;
const WEEKS = 7 * DAYS;

const DEFAULT_FAN_TTL = 7 * DAYS;
const DEFAULT_FORM_TTL = 1 * HOURS;
const DEFAULT_EVENT_TTL = 2 * DAYS;
const DEFAULT_ORDER_TTL = 3 * SECONDS;
const DEFAULT_SCHOOL_TTL = 4 * WEEKS;
const DEFAULT_SCHOOL_SEARCH_TTL = 3 * SECONDS;

export const LookupTTL: { [key: string]: number } = {};

export enum StorableType {
  Event = 'evt',
  Event_Category = 'evt_ctgr',
  Season_Category = 'ssn_ctgr',
  Form = 'frm',
  MySchool = 'mysch',
  Order = 'ordr',
  Season = 'ssn',
  School = 'schl',
  SchoolSearchResults = 'schlsrch',
  Team = 'team',
  TeamSearchResults = 'teamsrch',
  TicketSearchResults = 'tixrch',
  Sponsor = 'sponsor',
  SponsorV2 = 'sponsor_v2',
  EventSponsor = 'evt_sponsor',
  User = 'user',
  Event_List = 'evt_list',
  Season_List = 'ssn_list',
  UserService = 'user_srvc',
  Tax = 'tax'
}

LookupTTL[StorableType.Event] = DEFAULT_EVENT_TTL;
LookupTTL[StorableType.Form] = DEFAULT_FORM_TTL;
LookupTTL[StorableType.Order] = DEFAULT_ORDER_TTL;
LookupTTL[StorableType.School] = DEFAULT_SCHOOL_TTL;
LookupTTL[StorableType.SchoolSearchResults] = DEFAULT_SCHOOL_SEARCH_TTL;
LookupTTL[StorableType.MySchool] = DEFAULT_FAN_TTL;
LookupTTL[StorableType.Season] = DEFAULT_EVENT_TTL;

export { config };
