import {
  Activity,
  DISCOUNT_TYPES,
  DiscountInfo,
  DiscountPromoInfo,
  Event,
  EventSeason,
  EventType,
  EventTypeEnum,
  getProductTypeMeta,
  ProductSeating,
  ProductType,
  Promotion,
  PromotionData,
  ReservedSeat,
  PromotionLockResponse,
  School
} from '@gf/cross-platform-lib/interfaces';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';

import { getLevelGenderTitle, roundingDecimalNumber } from '@gf/cross-platform-lib/utils';
import {
  TheStore,
  createPromotionLock,
  deletePromoLock,
  updatePromoLock,
  getGlobalState,
  InitCartState,
  setGlobalState
} from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { EventInfoResult } from '..';
import { Level } from '@gf/cross-platform-lib/interfaces/Level';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import {
  DEFAULT_HOLDING_CART_TIME,
  MAX_TICKETS_PER_PROMO_PER_ORDER,
  PROMOTION_ERROR_MESSAGES,
  NEW_RELIC_ERROR_GROUPS,
  TaxUpdateTypes
} from '@gf/cross-platform-lib/constants';
import { ticketTypeMatchesSelectedSeat } from '@gf/cross-platform-lib/modules/event/util';
import { RenewalInfo } from '@gf/cross-platform-lib/modules';
import { TicketsAdditionalInfo } from './TicketAdditionalInfo';

export type TicketTypeSelection = {
  ticketType: ProductSeating;
  quantity: number;
  canBuy: boolean;
  updateAt?: number;
  createAt?: number;
  promos?: string[];
  accessCode?: string;
  isReservedSeating?: boolean;
  uniqueUrl?: string;
  upsellSelectedIndex?: number;
};

// constants
const CART = 'cart';
const DEFAULT_URL = '/assets/images/gofan-icon.png';
export const seatReservationKey = 'seatReservation';

export interface DayDateTime {
  day: string;
  date: string;
  time: string;
}

export interface SchoolCart {
  id: string;
  imgSrc: string;
  name: string;
  gofanPageEnabled: boolean;
  events: EventCart[] | SeasonCart[];
  gofanSchoolType?: string;
}

export interface EventCart {
  activity?: Activity;
  isSeason: boolean;
  updateAt?: number;
  id: string;
  name: string;
  type: EventType;
  ticketTypes: ProductSeating[];
  ticketsRemaining: number;
  isSoldOut: boolean;
  startDateTime: string;
  endDateTime: string;
  eventSalesInfo: any;
  schoolsTicket: any;
  ticketLimitPerOrder: number;
  eventIds: [];
  levels?: Level[];

  day: string;
  date: string;
  time: string;
  isSameDay: boolean;
  isAllDayEvent: boolean;
  timeZone: string;
  typeDesciption: string;
  venue?: string;

  financialSchoolID?: string;
  financialSchoolIndustryCode?: string;
  financialSchoolName?: string;
  financialSchoolState?: string;
  financialSchoolType?: string;

  tickets: TicketCart[];
  financialSchoolPublicPrivate?: string;
  zipCode: string;
  schoolZipCode: string;
  financialSchoolZipCode: string;
}

export interface SeasonCart {
  activity?: Activity;
  id: string;
  eventIds: string[];
  name: string;

  day: string;
  date: string;
  time: string;
  timeZone: string;
  isSameDay: boolean;
  isAllDayEvent: boolean;

  type: EventType;
  schoolsTicket: any;
  ticketTypes: ProductSeating[];
  tickets: TicketCart[];
  eventSalesInfo: any;
  ticketLimitPerOrder: number;
  startDateTime: string;
  endDateTime: string;
  isSeason: boolean;
  levels?: Level[];
  venue?: string;

  updateAt?: number;

  financialSchoolID?: string;
  financialSchoolIndustryCode?: string;
  financialSchoolName?: string;
  financialSchoolState?: string;
  financialSchoolType?: string;
  financialSchoolPublicPrivate?: string;
  zipCode: string;
  schoolZipCode: string;
  financialSchoolZipCode: string;
}

export interface TicketCartPromotion {
  id: string;
  promoId: number;
  promoCode: string;
  totalDiscount: number;
  discountQuantity: number;
  updatedAt: number;
  promotionData: PromotionData;
  discountPerTicket: number;
}

export interface UpdatedPromotionInfo {
  promoLockId: string;
  associatedTickets: TicketCart[];
  ticketPromotions: Map<string, { promotion: TicketCartPromotion }>;
  requestOverMaxLimit: boolean;
  originalLimit: number;
}

export interface TicketCart {
  hiddenFeeBase: number;
  schoolHuddleId?: string | undefined;
  opponentSchoolId?: string | undefined;
  updateAt?: number;
  createAt?: number;
  fee: number;
  id: string;
  isEnabled: boolean;
  name: string;
  price: number;
  quantity: number;
  limit: number;
  packCount: number;
  promos?: string[];
  accessCode?: string;
  promotion: TicketCartPromotion | null;
  seatsInfo?: SeatsInfo[];
  uniqueUrl?: string;
  ticketLimitPerOrder?: number;
  upsellSelectedIndex?: number;
}

export interface SeatsInfo {
  label: string;
  accessible: boolean;
  renewal?: boolean;
  renewalInfo?: RenewalInfo;
}

export class Cart {
  private static instance: Cart;
  cartSchools: SchoolCart[];
  lastTicketAddedDate?: Date;
  totalTax: number;
  taxUpdateType: TaxUpdateTypes;

  listeners: Set<Function>;

  private constructor() {
    this.listeners = new Set();
    this.cartSchools = [];
    this.totalTax = 0;
    this.taxUpdateType = TaxUpdateTypes.NONE;
  }

  public static getInstance(): Cart {
    if (!Cart.instance) {
      Cart.instance = new Cart();
    }
    return Cart.instance;
  }

  getSeatReservationCache = async () => {
    return JSON.parse((await TheStore.getItem(seatReservationKey)) || '{}');
  };

  setSeatReservationCache = async (seatReservation: Record<string, string>) => {
    await TheStore.setItem(seatReservationKey, JSON.stringify(seatReservation));
  };

  async init() {
    return TheStore?.getItem(CART).then(localCart => {
      if (localCart && !isEmpty(localCart.cartSchools)) {
        const cart = localCart as Cart;
        this.lastTicketAddedDate = cart.lastTicketAddedDate;
        this.cartSchools = cart.cartSchools;
        this.totalTax = cart.totalTax;
        this.taxUpdateType = cart.taxUpdateType;
        if (this.isExpired()) {
          this.emptyCart().then(() => {
            this.setCartState();
          });
        } else {
          this.setCartState();
        }
      }
    });
  }

  setCartState() {
    setGlobalState({
      ...getGlobalState(),
      cart: {
        cartSchools: [...this.sortTicket()],
        isExpired: this.isExpired(),
        isEmpty: this.isEmpty(),
        lastTicketAddedDate: this.lastTicketAddedDate,
        totalTax: this.totalTax,
        taxUpdateType: this.taxUpdateType
      }
    });
  }

  resetCartState() {
    setGlobalState({
      ...getGlobalState(),
      cart: {
        ...InitCartState
      }
    });
  }

  async addTicket({
    school,
    event,
    eventInfo,
    tickets,
    selectedSeats
  }: {
    school: School;
    event: Event;
    eventInfo: EventInfoResult;
    tickets: TicketTypeSelection[];
    selectedSeats?: ReservedSeat[];
  }): Promise<void> {
    if (this.isExpired()) {
      await this.init();
    }
    const { schoolToAdd, eventToAdd, ticketsToAdd } = this.convertData(
      school,
      event,
      eventInfo,
      tickets,
      selectedSeats
    );

    if (!this.hasSchool(school.huddleId)) {
      this.cartSchools.push(schoolToAdd);
    } else {
      const schoolIndex = this.cartSchools.map(cartSchool => cartSchool.id).indexOf(school.huddleId);
      const matchedSchool: SchoolCart = this.cartSchools[schoolIndex];
      if (!this.hasEvent(matchedSchool, event.id)) {
        matchedSchool.events.push(eventToAdd);
      } else {
        const eventIndex = matchedSchool.events.map(cartEvent => cartEvent.id).indexOf(event.id);
        const matchedEvent = matchedSchool.events[eventIndex];
        ticketsToAdd.forEach(ticketToAdd => {
          if (!this.hasTicket(matchedEvent, ticketToAdd.id)) {
            matchedEvent.tickets.push(ticketToAdd);
          } else {
            const ticketIndex = matchedEvent.tickets.map(cartTicket => cartTicket.id).indexOf(ticketToAdd.id);
            const matchedTicket = matchedEvent.tickets[ticketIndex];
            matchedTicket.quantity = ticketToAdd.quantity;
            matchedTicket.seatsInfo = ticketToAdd.seatsInfo;
          }
        });
      }
    }
    this.lastTicketAddedDate = new Date();
    this.sortSchoolsAndEvents();
    await this.saveCart(this);
    this.setCartState();
  }

  updateEventSalesInfo = async (newEvent: {
    eventId: string;
    eventSoldOut: boolean;
    eventTotalRemainingQuantity?: number;
  }) => {
    this.cartSchools.forEach(school => {
      school.events.forEach(event => {
        if (newEvent.eventId === event.id && !isEmpty(event.eventSalesInfo)) {
          event.eventSalesInfo.eventSoldOut = newEvent.eventSoldOut;
          event.eventSalesInfo.eventTotalRemainingQuantity = newEvent.eventTotalRemainingQuantity;
        }
      });
    });
    await this.saveCart(this);
    this.setCartState();
  };

  updateProductSaleMap = async (newProductSaleMap: {
    soldOut: boolean;
    remainingQuantity: number;
    productId: string | number;
  }) => {
    this.cartSchools.forEach(school => {
      school.events.forEach(event => {
        const { productSalesMap } = event.eventSalesInfo || {};
        event.tickets.forEach(ticket => {
          const ticketSalesInfo = productSalesMap[ticket.id];
          if (isEqual(ticket.id, newProductSaleMap.productId)) {
            ticketSalesInfo.soldOut = newProductSaleMap.soldOut;
            ticketSalesInfo.remainingQuantity = newProductSaleMap.remainingQuantity;
          }
        });
      });
    });
    await this.saveCart(this);
    this.setCartState();
  };

  updateSchoolTicketLimit = async (newSchoolLimit: { limitTicket: number; schoolId: string; totalSales: number }) => {
    this.cartSchools.forEach(school => {
      school.events.forEach(event => {
        const { schoolSalesMap } = event.eventSalesInfo || {};
        event.schoolsTicket.forEach((school: { schoolId: string | number; schoolLimit: number }) => {
          if (isEqual(school.schoolId, newSchoolLimit.schoolId)) {
            school.schoolLimit = newSchoolLimit.limitTicket;
            schoolSalesMap[school.schoolId] = newSchoolLimit.totalSales;
          }
        });
      });
    });
    await this.saveCart(this);
    this.setCartState();
  };

  updateEventLimitPerOrder = async (newLimit: {
    eventId?: string;
    ticketLimitPerOrder: number;
    tickets?: Array<{
      productId: string;
      remainingQuantity: number;
      soldOut: boolean;
      ticketLimitPerOrder: number;
    }>;
    eventTotalRemainingQuantity: number;
    seasonId?: string;
    eventSoldOut: boolean;
    id: string;
  }) => {
    this.cartSchools.forEach(school => {
      school.events.forEach(event => {
        if (isEqual(newLimit.id, event.id)) {
          event.ticketLimitPerOrder = newLimit.ticketLimitPerOrder;
        }
      });
    });
    await this.saveCart(this);
    this.setCartState();
  };

  getAccessCodeByTicketId = async (ticketId: string) => {
    let foundId = '';
    this.cartSchools.forEach(school => {
      school.events.forEach(event => {
        event.tickets.forEach(ticket => {
          if (isEqual(ticket.id, ticketId)) {
            foundId = ticket.promos ? ticket.promos[0] : '';
          }
        });
      });
    });
    return foundId;
  };

  updateAccessCodeByTicketId = async (ticketId: string, code: string) => {
    this.cartSchools.forEach(school => {
      school.events.forEach(event => {
        event.tickets.forEach(ticket => {
          if (isEqual(ticket.id, ticketId)) {
            ticket.promos = [code];
          }
        });
      });
    });
    await this.saveCart(this);
    this.setCartState();
  };

  addSeasonTicket = async ({
    school,
    season,
    tickets,
    selectedSeats
  }: {
    school: School;
    season: EventSeason;
    tickets: any;
    selectedSeats?: ReservedSeat[];
  }) => {
    if (this.isExpired()) {
      await this.init();
    }

    const { schoolToAdd, seasonsToAdd, ticketsToAdd } = this.convertSeasonData(school, season, tickets, selectedSeats);

    if (!this.hasSchool(school.huddleId)) {
      this.cartSchools.push(schoolToAdd);
    } else {
      const schoolIndex = this.cartSchools.map(cartSchool => cartSchool.id).indexOf(school.huddleId);
      const matchedSchool: SchoolCart = this.cartSchools[schoolIndex];
      if (!this.hasEvent(matchedSchool, season.id)) {
        matchedSchool.events.push(seasonsToAdd);
      } else {
        const eventIndex = matchedSchool.events.map(cartEvent => cartEvent.id).indexOf(season.id);
        const matchedEvent = matchedSchool.events[eventIndex];
        ticketsToAdd.forEach(ticketToAdd => {
          if (!this.hasTicket(matchedEvent, ticketToAdd.id)) {
            matchedEvent.tickets.push(ticketToAdd);
          } else {
            const ticketIndex = matchedEvent.tickets.map(cartTicket => cartTicket.id).indexOf(ticketToAdd.id);
            const matchedTicket = matchedEvent.tickets[ticketIndex];
            matchedTicket.quantity = ticketToAdd.quantity;
            matchedTicket.seatsInfo = ticketToAdd.seatsInfo;
            matchedTicket.uniqueUrl = ticketToAdd.uniqueUrl;
          }
        });
      }
    }
    this.lastTicketAddedDate = new Date();
    this.sortSchoolsAndEvents();
    await this.saveCart(this);
    this.setCartState();
  };

  getTicket(ticketId: string) {
    const foundTicket: TicketCart = {} as TicketCart;
    this.cartSchools.forEach(school => {
      school.events.forEach(event => {
        event.tickets.forEach(ticket => {
          if (String(ticket.id) === String(ticketId)) {
            Object.assign(foundTicket, ticket);
          }
        });
      });
    });
    return foundTicket;
  }

  getCartEvents(eventId: string) {
    let foundEvents: Array<EventCart | SeasonCart> = [];
    this.cartSchools.forEach(school => {
      school.events.forEach(event => {
        if (event.id === eventId) {
          foundEvents.push(event);
        }
      });
    });
    return foundEvents;
  }

  groupEventTicketTypesById(events: EventCart[] | SeasonCart[]) {
    const val = events[0];
    events.forEach((obj, index) => {
      if (index === 0) return;
      const { tickets, ticketTypes } = obj;
      val.tickets.push(...tickets);
      val.ticketTypes.push(...ticketTypes);
    });
    return val;
  }

  getEventWithDistributionSchools(eventId: string): EventCart | SeasonCart {
    const cartEvents: EventCart[] | SeasonCart[] = cloneDeep(this.getCartEvents(eventId));
    return cartEvents.length === 1 ? cartEvents[0] : this.groupEventTicketTypesById(cartEvents);
  }

  getCartTotal(): {
    total: number;
    subTotal: number;
    serviceFees: number;
    revenue: number;
    totalDiscount: number;
    totalTax: number;
  } {
    let subTotal = 0;
    let serviceFees = 0;
    let totalDiscount = 0;
    let hiddenFeeBase = 0;
    this.cartSchools.forEach(school =>
      school.events.forEach(event =>
        event.tickets.forEach(ticket => {
          subTotal = subTotal + ticket.price * ticket.quantity;
          serviceFees = serviceFees + ticket.fee * ticket.quantity;
          hiddenFeeBase = hiddenFeeBase + ticket.hiddenFeeBase * ticket.quantity;
          totalDiscount = ticket.promotion?.totalDiscount ? totalDiscount + ticket.promotion?.totalDiscount : 0;
        })
      )
    );

    const total = subTotal + serviceFees + this.totalTax;
    const revenue = subTotal - totalDiscount - hiddenFeeBase;

    return {
      subTotal,
      serviceFees,
      total: roundingDecimalNumber(total, true),
      revenue,
      totalDiscount,
      totalTax: this.totalTax
    };
  }

  async changeTicketQuantity(ticketId: string | number, quantity: number): Promise<Cart> {
    this.cartSchools.forEach(school => {
      school.events.forEach(event => {
        event.tickets.forEach(ticket => {
          if (isEqual(ticket.id, ticketId)) {
            ticket.quantity = quantity;
            ticket.updateAt = Date.now();
            event.updateAt = Date.now();
          }
        });
      });
    });
    this.setCartState();
    await this.saveCart(Cart.getInstance());
    return Cart.getInstance();
  }

  async changeTotalTax(totalTax: number): Promise<void> {
    if (this.totalTax !== totalTax) {
      this.totalTax = totalTax;
      this.setCartState();
      await this.saveCart(Cart.getInstance());
    }
  }

  async setTaxUpdateType(taxUpdateType: TaxUpdateTypes): Promise<void> {
    this.taxUpdateType = taxUpdateType;
    this.setCartState();
    await this.saveCart(Cart.getInstance());
  }

  async triggerTax(tax: number): Promise<void> {
    this.taxUpdateType = TaxUpdateTypes.UPDATE_WHEN_INVALID;
    this.totalTax = tax;
    this.setCartState();
    await this.saveCart(Cart.getInstance());
  }

  async emptyCart(orderSuccess = false): Promise<void> {
    if (!orderSuccess) {
      this.removePromotionLockInCart();
    }
    this.cartSchools = [] as SchoolCart[];
    this.lastTicketAddedDate = undefined;
    this.resetCartState();
    await TheStore.removeItem(CART);
    await TheStore.removeItem(seatReservationKey);
  }

  async removeTicket(ticketID: string | number): Promise<Cart> {
    //remove promo lock has no associated tickets or update promo lock if has associated tickets
    this.removePromotionLockByTicketId(ticketID);
    this.cartSchools.forEach(school =>
      school.events.forEach(event => {
        event.tickets.forEach(ticket => {
          if (isEqual(ticket.id, ticketID)) {
            event.tickets = event.tickets.filter(ticket => !isEqual(ticket.id, ticketID));
          }
        });
      })
    );

    //remove empty event
    this.cartSchools.forEach(school =>
      school.events.filter(() => {
        school.events = remove([...school.events], event => !isEmpty(event.tickets));
      })
    );

    //remove empty school
    this.cartSchools = this.cartSchools.filter(school => !isEmpty(school.events));
    let isCartEmpty = false;
    if (isEmpty(this.cartSchools)) {
      isCartEmpty = true;
      await this.emptyCart();
    }
    await this.saveCart(this);
    if (!isCartEmpty) {
      this.recalculatePromotionInCart();
    }
    this.setCartState();
    return this;
  }

  async removeEvent(eventId: string | number) {
    this.removePromotionLockByEventId(eventId);
    this.cartSchools = this.cartSchools
      .map(cartSchool => {
        return { ...cartSchool, events: cartSchool.events.filter(event => !isEqual(eventId, event.id)) };
      })
      .filter(cartSchool => !isEmpty(cartSchool.events));

    if (isEmpty(this.cartSchools)) {
      await this.emptyCart();
    }
    await this.saveCart(this);
    this.setCartState();
    return this;
  }

  async removeSeat(seatLabel: string) {
    const seatReservation: Record<string, string> = await this.getSeatReservationCache();

    const newCartSchools = this.cartSchools
      .map(school => {
        const newSchool = { ...school };
        newSchool.events = newSchool.events
          .map(event => {
            const newEvent = { ...event };
            newEvent.tickets = newEvent.tickets
              .map(ticket => {
                if (ticket.seatsInfo && ticket.seatsInfo.some(seat => seat.label === seatLabel)) {
                  const newTicket = { ...ticket };
                  newTicket.seatsInfo = newTicket.seatsInfo?.filter(seat => seat.label !== seatLabel);
                  newTicket.quantity -= 1;

                  const cacheKey = `${ticket.id}-${seatLabel}`;
                  delete seatReservation[cacheKey];
                  return newTicket.quantity > 0 ? newTicket : null;
                } else {
                  return ticket;
                }
              })
              .filter(ticket => ticket !== null) as TicketCart[];

            return newEvent.tickets.length > 0 ? newEvent : null;
          })
          .filter(event => event !== null) as (EventCart | SeasonCart)[];

        return newSchool.events.length > 0 ? newSchool : null;
      })
      .filter(school => school !== null);

    this.cartSchools = newCartSchools as SchoolCart[];
    await this.setSeatReservationCache(seatReservation);
    await this.saveCart(this);
    this.setCartState();
    return this;
  }

  isExpired(): boolean {
    if (this.lastTicketAddedDate) {
      const now = dayjs(new Date());
      const lastTicketAddedDate = dayjs(this.lastTicketAddedDate);
      const isExpired = now.diff(lastTicketAddedDate, 'seconds', true) > 60 * 15;
      if (isExpired) {
        return true;
      }
    }
    return false;
  }

  //get remaining time to reset cart
  getCartRemainingTime() {
    if (!this.lastTicketAddedDate) {
      return 0;
    }
    const now = dayjs(new Date());
    const lastTicketAddedDate = dayjs(this.lastTicketAddedDate);
    const expireTime = DEFAULT_HOLDING_CART_TIME - now.diff(lastTicketAddedDate, 'seconds', true) * 1000;

    if (expireTime < 0) {
      return 0;
    } else if (expireTime > DEFAULT_HOLDING_CART_TIME) {
      return DEFAULT_HOLDING_CART_TIME;
    }
    return expireTime;
  }

  isEmpty(): boolean {
    return this.cartSchools.length === 0;
  }

  sortTicket() {
    this.cartSchools.forEach(school =>
      school.events.forEach(event =>
        event.tickets.sort((ticketA, ticketB) => {
          if (ticketA.price === ticketB.price) {
            if (ticketA.name < ticketB.name) {
              return -1;
            }
            if (ticketA.name > ticketB.name) {
              return 1;
            }
            return 0;
          }
          return ticketB.price - ticketA.price;
        })
      )
    );
    return this.cartSchools;
  }

  async saveCart(cart: Cart): Promise<void> {
    await TheStore.setItem(CART, cart);
  }

  getTicketTypeIds = (): Set<number> => {
    const ids = new Set<number>();
    for (const school of this.cartSchools) {
      for (const event of school.events) {
        for (const ticket of event.tickets) {
          ids.add(Number(ticket.id));
        }
      }
    }
    return ids;
  };

  getTicketCarts = (): Set<TicketCart> => {
    const tickets = new Set<TicketCart>();
    for (const school of this.cartSchools) {
      for (const event of school.events) {
        for (const ticket of event.tickets) {
          tickets.add(ticket);
        }
      }
    }
    return tickets;
  };

  getTicketCartsByIds = (ids: number[]) => {
    const tickets = new Set<TicketCart>();
    for (const school of this.cartSchools) {
      for (const event of school.events) {
        for (const ticket of event.tickets) {
          if (ids.includes(Number(ticket.id))) {
            tickets.add(ticket);
          }
        }
      }
    }
    return tickets;
  };

  getTicketCartsById = (id: string) => {
    for (const school of this.cartSchools) {
      for (const event of school.events) {
        for (const ticket of event.tickets) {
          if (id == ticket.id) {
            return ticket;
          }
        }
      }
    }
    return null;
  };

  getPromotionLockIdOfEvent = (eventId: string | number): Set<string> => {
    const idSet = new Set<string>();
    for (const school of this.cartSchools) {
      const events = school.events.filter(event => String(event.id) === String(eventId));
      for (const event of events) {
        for (const ticket of event.tickets) {
          if (ticket.promotion?.id) {
            idSet.add(ticket.promotion.id);
          }
        }
      }
    }
    return idSet;
  };

  getPromotionLockIdInCart = (): Set<string> => {
    const idSet = new Set<string>();
    const { ticketsHasPromo, ticketsHasNoPromo } = this.getTicketCartsWithPromotion();
    const ticketsWithPromo = Array.from(ticketsHasPromo);
    const ticketsWithoutPromo = Array.from(ticketsHasNoPromo);
    for (const ticket of ticketsWithPromo) {
      idSet.add(ticket.promotion!.id);
    }
    for (const ticket of ticketsWithoutPromo) {
      if (ticket.promos && !isEmpty(ticket.promos)) {
        idSet.add(ticket.promos[0]);
      }
    }
    return idSet;
  };

  validateAccessLockInCart = async (code = '', showModal?: Function) => {
    let index = 0;
    const ticketsPromo = this.getTicketCartsWithAccessCode();
    const haveTicketsWithExpiredCode = ticketsPromo.some(ticket => code === ticket.accessCode);
    if (!haveTicketsWithExpiredCode) {
      return;
    }
    try {
      for (const ticket of ticketsPromo) {
        index = ticketsPromo.indexOf(ticket);
        if (ticket.promos && !isEmpty(ticket.promos) && code === ticket.accessCode) {
          const forms = TicketsAdditionalInfo.getInstance().getFormToDetectEmptyForm(
            TicketsAdditionalInfo.getInstance().getAdditionalFormsOfTicket(ticket.id)
          );
          await deletePromoLock(ticket.promos[0]);
          if (!isEmpty(forms)) {
            await TicketsAdditionalInfo.getInstance().removeTicketAdditionalForms(
              ticket.id,
              forms.map(form => form.id)
            );
          }
          await this.removeTicket(ticket.id);
        }
      }
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'Cart-validateAccessLockInCart',
        customMessage: `Error while validating and unlocking access codes in cart`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.AccessAndPromoCodes,
        data: { code, ticketsPromo }
      });
    }
    if (index === ticketsPromo.length - 1 && typeof showModal === 'function') {
      await showModal();
    }
  };

  getTicketCartsHasPromotion = (): Set<TicketCart> => this.getTicketCartsWithPromotion().ticketsHasPromo;

  getTicketCartsHasNoPromotion = (): Set<TicketCart> => this.getTicketCartsWithPromotion().ticketsHasNoPromo;

  getTicketCartsWithAccessCode = () => Array.from(this.getTicketCarts()).filter(ticket => !isEmpty(ticket.promos));

  getTicketCartsWithPromotion = (): { ticketsHasPromo: Set<TicketCart>; ticketsHasNoPromo: Set<TicketCart> } => {
    const ticketsHasPromo = new Set<TicketCart>();
    const ticketsHasNoPromo = new Set<TicketCart>();
    for (const school of this.cartSchools) {
      for (const event of school.events) {
        for (const ticket of event.tickets) {
          if (ticket.promotion) {
            ticketsHasPromo.add(ticket);
          } else {
            ticketsHasNoPromo.add(ticket);
          }
        }
      }
    }
    return {
      ticketsHasPromo,
      ticketsHasNoPromo
    };
  };

  getTicketsAssociatedByPromo = (promoId: number, excludedTicketIds: string[] = []) => {
    const cartService = this;
    return Array.from(cartService.getTicketCartsHasPromotion()).filter(
      ticket => ticket.promotion!.promoId === promoId && !excludedTicketIds.some(id => id === String(ticket.id))
    );
  };

  isAnyProductIdsExistedInCart = (associatedProducIds: number[]) => {
    const ticketIds = this.getTicketTypeIds();
    return associatedProducIds.some(id => ticketIds.has(id));
  };

  isEveryAssociatedProductsHasPromo = (associatedProducIds: number[]) => {
    const ticketCarts = Array.from(this.getTicketCartsByIds(associatedProducIds));
    return ticketCarts.every(ticket => !!ticket.promotion);
  };

  isPromoCodeAppliedOnAllAssociatedProducts = (associatedProducIds: number[], promoCode: string) => {
    const ticketCarts = Array.from(this.getTicketCartsByIds(associatedProducIds));
    return ticketCarts.every(ticket => ticket.promotion?.promoCode === promoCode);
  };

  applyPromotion = async (
    promo: Promotion,
    onCodeAdded: (data: UpdatedPromotionInfo) => void,
    onError: (responses: PromotionLockResponse, errorMessage: string | null) => void,
    accessCodeWithDiscount = false
  ) => {
    const { promotion } = promo._embedded;
    const { eventId, seasonId } = promo;
    const { productAssociations, code: promoCode } = promotion;
    const cartService = this;
    const associatedTickets = productAssociations
      .map(product => cartService.getTicket(String(product.productId)))
      .filter(ticket => !isEmpty(ticket))
      .filter(ticket => isEmpty(ticket.promotion));
    const { totalQuantity } = Cart.getTicketsState(associatedTickets);
    const requestingQty =
      totalQuantity <= MAX_TICKETS_PER_PROMO_PER_ORDER ? totalQuantity : MAX_TICKETS_PER_PROMO_PER_ORDER;
    const requestOverMaxLimit = totalQuantity <= MAX_TICKETS_PER_PROMO_PER_ORDER ? false : true;
    const responses = accessCodeWithDiscount
      ? promo
      : await createPromotionLock(eventId, promoCode, requestingQty, !!seasonId);
    if (!('message' in responses)) {
      const offeredPromo = responses as Promotion;

      if (
        associatedTickets.every(ticket => Cart.isTicketExceededLimit(ticket, offeredPromo.quantityLocked)) &&
        !accessCodeWithDiscount
      ) {
        onError(responses, PROMOTION_ERROR_MESSAGES.CODE_EXCEED_USAGE_LIMIT);
        deletePromoLock(offeredPromo.id);
      } else {
        const validAssociatedTickets = associatedTickets.filter(
          ticket => !Cart.isTicketExceededLimit(ticket, offeredPromo.quantityLocked)
        );
        const ticketPromotions = Cart.calculatePromotion(validAssociatedTickets, offeredPromo);
        for (const associatedTicket of validAssociatedTickets) {
          associatedTicket.promotion = ticketPromotions.get(associatedTicket.id)!.promotion;
          await this.updateTicket(associatedTicket.id, associatedTicket);
        }
        onCodeAdded({
          associatedTickets: validAssociatedTickets,
          ticketPromotions,
          promoLockId: offeredPromo.id,
          requestOverMaxLimit,
          originalLimit: offeredPromo?._embedded?.promotion?.limit || 0
        });
      }
    } else {
      onError(responses, null);
    }
  };

  removeTicketsPromotion = async (ticketId: string, onPromoRemoved?: (promoId: number) => void) => {
    const cartInstance = this;
    const ticket = cartInstance.getTicketCartsById(ticketId);

    if (ticket && ticket.promotion) {
      const { promotion } = ticket;
      const { id, promoId } = promotion;
      try {
        deletePromoLock(String(id));
      } catch (err: any) {
        recordError(err, {
          originatingFunction: 'Cart-removeTicketsPromotion',
          customMessage: `Error while removing promotion lock of ticket ${ticketId}`,
          errorGroup: NEW_RELIC_ERROR_GROUPS.AccessAndPromoCodes,
          data: { ticketId, promotion }
        });
      }
      ticket.promotion = null;
      cartInstance.updateTicket(ticket.id, ticket);
      if (typeof onPromoRemoved === 'function') {
        onPromoRemoved(promoId);
      }
    }
  };

  removePromotionLockByPromoId = (promoId: number, onPromoRemoved?: (promoId: number) => void) => {
    const cartInstance = this;
    const tickets = Array.from(cartInstance.getTicketsAssociatedByPromo(promoId));
    if (tickets.length > 0) {
      const promoLockId = tickets[0].promotion!.id;
      try {
        deletePromoLock(promoLockId);
      } catch (err: any) {
        recordError(err, {
          originatingFunction: 'Cart-removePromotionLockByPromoId',
          customMessage: `Error while removing promotion lock of promo ${promoId}`,
          errorGroup: NEW_RELIC_ERROR_GROUPS.AccessAndPromoCodes,
          data: { promoId, tickets }
        });
      }
      for (const { id } of tickets) {
        cartInstance.removeTicketsPromotion(id, onPromoRemoved);
      }
    }
  };

  removePromotionLockByEventId = (eventId: string | number) => {
    const promoLockIds = Array.from(this.getPromotionLockIdOfEvent(eventId));
    try {
      // Request API remove un-used protion lock of.
      promoLockIds.forEach(id => deletePromoLock(id));
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'Cart-removePromotionLockByEventId',
        customMessage: `Error while removing promotion lock of event ${eventId}`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.AccessAndPromoCodes,
        data: { eventId, promoLockIds }
      });
    }
  };

  removePromotionLockByTicketId = (ticketId: string | number) => {
    //remove promo lock has no associated tickets or update promo lock if has associated tickets
    let associatedTickets: TicketCart[] = [];
    const promotion = this.getTicketCartsById(String(ticketId))?.promotion;
    if (promotion) {
      associatedTickets = this.getTicketsAssociatedByPromo(promotion.promoId, [String(ticketId)]);
      const hasNoAssociatedTickets = isEmpty(associatedTickets);
      try {
        if (hasNoAssociatedTickets) {
          deletePromoLock(promotion.id);
        }
        // don't forget to call recalculatePromotionInCart() after cart updated
        // to update promotion for another ticket
      } catch (err: any) {
        recordError(err, {
          originatingFunction: 'Cart-removePromotionLockByTicketId',
          customMessage: `Error while removing promotion lock (or updating the lock) of ticket ${ticketId}`,
          errorGroup: NEW_RELIC_ERROR_GROUPS.AccessAndPromoCodes,
          data: { ticketId, promotion }
        });
      }
    }
  };

  removePromotionLockInCart = () => {
    const promoLockIds = Array.from(this.getPromotionLockIdInCart());
    try {
      // Request API remove un-used promotion lock.
      promoLockIds.forEach(id => deletePromoLock(id));
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'Cart-removePromotionLockInCart',
        customMessage: `Error while removing promotion lock(s) as part of emptying the cart after an unsuccessful order`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.AccessAndPromoCodes,
        data: { promoLockIds }
      });
    }
  };

  getDiscountInfo = (): DiscountInfo | null => {
    const cartInstance = this;
    const tickets = Array.from(cartInstance.getTicketCartsHasPromotion());
    if (tickets.length === 0) return null;
    const total = tickets.reduce((sum, ticket) => {
      return sum + ticket.promotion!.totalDiscount;
    }, 0);

    const promoMap = new Map<number, DiscountPromoInfo>();
    for (const ticket of tickets) {
      const accessCode = ticket.accessCode || '';
      const promotion = ticket.promotion!;
      const id = promotion.promoId;
      const code = promotion.promoCode;
      const discount = promotion!.totalDiscount;
      const discountType = promotion.promotionData.discountType;
      const value = promotion.promotionData.value;
      const promo = promoMap.get(id);
      if (promo) {
        promoMap.set(id, {
          ...promo,
          discount: promo.discount + discount,
          accessCode
        });
      } else {
        promoMap.set(id, {
          accessCode,
          id,
          code,
          discount,
          discountType,
          value
        });
      }
    }
    const promoCodes: DiscountPromoInfo[] = [];
    promoMap.forEach(promo => promoCodes.push(promo));

    return {
      total,
      promoCodes
    };
  };

  recalculatePromotion = async (
    ticketId: string,
    associatedTicketsHasNoPromo: TicketCart[] = [],
    onPromotionUpdated?: (data: UpdatedPromotionInfo) => void
  ) => {
    // Re calculate promotion after ticket quantity changed.
    const ticket = this.getTicketCartsById(ticketId);
    if (!ticket || !isEmpty(ticket.promos)) return;

    const { promotion } = ticket;
    const cartService = this;
    if (promotion) {
      const { id } = promotion;
      const onGoingToAddPromoTickets = associatedTicketsHasNoPromo.map(ticket => ({
        ...ticket,
        promotion: {
          ...promotion,
          discountQuantity: 0,
          totalDiscount: 0
        }
      }));
      const associatedTicketsByPromoId: TicketCart[] = cartService.getTicketsAssociatedByPromo(promotion.promoId, []);
      const associatedTickets = [...associatedTicketsByPromoId, ...onGoingToAddPromoTickets];
      const { totalQuantity } = Cart.getTicketsState(associatedTickets);
      const requestingQty =
        totalQuantity <= MAX_TICKETS_PER_PROMO_PER_ORDER ? totalQuantity : MAX_TICKETS_PER_PROMO_PER_ORDER;
      const requestOverMaxLimit = totalQuantity <= MAX_TICKETS_PER_PROMO_PER_ORDER ? false : true;
      const responses = await updatePromoLock(id, requestingQty);
      if (!('message' in responses)) {
        const offeredPromo = responses as Promotion;
        const validAssociatedTickets = associatedTickets.filter(
          ticket => !Cart.isTicketExceededLimit(ticket, offeredPromo.quantityLocked)
        );
        const ticketPromotions = Cart.calculatePromotion(validAssociatedTickets, offeredPromo);
        for (const associatedTicket of validAssociatedTickets) {
          associatedTicket.promotion = ticketPromotions.get(associatedTicket.id)!.promotion;
          await this.updateTicket(associatedTicket.id, associatedTicket);
        }
        if (typeof onPromotionUpdated === 'function') {
          onPromotionUpdated({
            associatedTickets: validAssociatedTickets,
            ticketPromotions,
            promoLockId: offeredPromo.id,
            requestOverMaxLimit,
            originalLimit: offeredPromo?._embedded?.promotion?.limit || 0
          });
        }
      }
    }
  };

  recalculatePromotionInCart = async () => {
    const cartService = this;
    const { ticketsHasNoPromo, ticketsHasPromo } = cartService.getTicketCartsWithPromotion();
    const promoTickets = Array.from(ticketsHasPromo);
    const nopromoTickets = Array.from(ticketsHasNoPromo);

    const recalculatedCodes = new Set<String>();
    for (const promoTicket of promoTickets) {
      const promotion = promoTicket.promotion!;
      const { promoCode } = promotion;
      if (!recalculatedCodes.has(promoCode)) {
        const associatedTicketsHasNoPromo = nopromoTickets.filter(ticket =>
          promotion.promotionData.productAssociations.some(({ productId }) => String(productId) === String(ticket.id))
        );
        cartService.recalculatePromotion(promoTicket.id, associatedTicketsHasNoPromo);
        recalculatedCodes.add(promoCode);
      }
    }
  };

  updateTicket = async (ticketId: string, newTicket: TicketCart) => {
    for (const cartSchool of this.cartSchools) {
      for (const event of cartSchool.events) {
        event.tickets = event.tickets.map(ticket =>
          String(ticket.id) === String(ticketId) ? { ...ticket, ...newTicket } : ticket
        );
      }
    }
    await this.saveCart(this);
    this.setCartState();
  };

  updateTicketPromotion = async (ticketId: string, promotion: TicketCartPromotion) => {
    for (const cartSchool of this.cartSchools) {
      for (const event of cartSchool.events) {
        event.tickets = event.tickets.map(ticket =>
          String(ticket.id) === String(ticketId) ? { ...ticket, promotion } : ticket
        );
      }
    }
    await this.saveCart(this);
    this.setCartState();
  };

  recalculateNotAssociateTicket = async (ticketId: string, quantity: number) => {
    const ticket = this.getTicketCartsById(ticketId);
    if (!ticket) return;

    const { promotion } = ticket;
    if (promotion && promotion?.id) {
      const { id } = promotion;
      const responses = await updatePromoLock(id, quantity);
      if (!('message' in responses)) {
        const offeredPromo = responses as Promotion;
        const ticketPromotions = Cart.calculatePromotion([ticket], offeredPromo);
        const ticketCartPromotion = ticketPromotions.get(ticket.id)!.promotion;
        await this.updateTicketPromotion(ticket.id, ticketCartPromotion);
      }
    }
    return;
  };

  recalculateAccessCode = async (ticketId: string, requestingQty: number) => {
    const ticket = this.getTicketCartsById(ticketId);
    if (!isEmpty(ticket) && ticket && ticket.promos) {
      await updatePromoLock(ticket.promos[0], requestingQty);
    }
  };

  // util
  convertData(
    school: School,
    event: Event,
    eventInfo: EventInfoResult,
    tickets: TicketTypeSelection[],
    reservedSeats?: ReservedSeat[]
  ): { schoolToAdd: SchoolCart; eventToAdd: EventCart; ticketsToAdd: TicketCart[] } {
    const ticketsToAdd: TicketCart[] = [];
    tickets.forEach(ticket => {
      if (ticket.quantity > 0) {
        const currTicket = ticket.ticketType;
        const newTicketCart = {
          fee: currTicket.fee,
          id: currTicket.id,
          isEnabled: currTicket.isEnabled,
          name: currTicket.name,
          price: currTicket.price,
          quantity: ticket.quantity,
          packCount: currTicket.packCount,
          updateAt: ticket.updateAt,
          createAt: ticket.createAt,
          promos: ticket.promos,
          accessCode: ticket.accessCode,
          hiddenFeeBase: currTicket.hiddenFeeBase,
          schoolHuddleId: event.schoolHuddleId || school.id,
          opponentSchoolId: event.opponentSchoolId || '',
          uniqueUrl: ticket.uniqueUrl,
          ticketLimitPerOrder: currTicket.ticketLimitPerOrder,
          upsellSelectedIndex: ticket.upsellSelectedIndex
        } as TicketCart;
        if (currTicket.isReservedSeating) {
          const seats: SeatsInfo[] = [];
          reservedSeats?.forEach(seat => {
            if (ticketTypeMatchesSelectedSeat(currTicket, seat)) {
              seats.push({ label: seat.id, accessible: seat.selectedSeat.accessible });
            }
          });
          newTicketCart.seatsInfo = seats;
        }
        ticketsToAdd.push(newTicketCart);
      }
    });

    const eventType = this.getEventTypeFromProductType(event) ?? ProductType.TICKET;
    const { day, date, time } = this.parseSchedule(
      event.endDateTime,
      event.startDateTime,
      eventInfo.isSameDay,
      event.isAllDayEvent,
      eventType
    );

    const eventToAdd: EventCart = {
      activity: event.activity,
      id: event.id,
      name: eventInfo.eventName,
      type: eventType,
      ticketTypes: event.ticketTypes,
      ticketsRemaining: eventInfo.ticketsRemaining,
      eventIds: [],
      eventSalesInfo: event.salesInfo,
      schoolsTicket: event.schoolsTicket,
      maxCapacity: event.maxCapacity,
      isSoldOut: eventInfo.isSoldOut,
      startDateTime: event.startDateTime,
      endDateTime: event.endDateTime,
      ticketLimitPerOrder: event.ticketLimitPerOrder,
      updateAt: Date.now(),

      day: day,
      date: date,
      time: time,
      timeZone: event.timeZone,
      isSameDay: eventInfo.isSameDay,
      isAllDayEvent: event.isAllDayEvent,
      levels: event.levels,

      isSeason: false,

      typeDesciption: (event.eventTypeName || eventInfo.eventType) + eventInfo.eventTypeDescription,
      venue: eventType ? this.getVenue(eventType, event.venue?.name) : '',

      tickets: ticketsToAdd,

      financialSchoolID: event.financialSchoolID,
      financialSchoolIndustryCode: event.financialSchoolIndustryCode,
      financialSchoolName: event.financialSchoolName,
      financialSchoolState: event.financialSchoolState,
      financialSchoolType: event.financialSchoolType,
      financialSchoolPublicPrivate: event.financialSchoolPublicPrivate,
      zipCode: event?.venue?.zip || school.zipCode || event?.financialSchoolZipCode,
      schoolZipCode: school.zipCode,
      financialSchoolZipCode: event?.financialSchoolZipCode
    } as EventCart;

    const schoolToAdd: SchoolCart = {
      id: school.huddleId,
      imgSrc: school.logoUrl ? `${school.logoUrl}` : DEFAULT_URL,
      name: school.customPageName || school.name,
      gofanPageEnabled: school.gofanPageEnabled || false,
      events: [eventToAdd],
      gofanSchoolType: school.gofanSchoolType
    };

    return { schoolToAdd, ticketsToAdd, eventToAdd };
  }

  private convertSeasonData(
    school: School,
    season: EventSeason,
    tickets: TicketTypeSelection[],
    reservedSeats?: ReservedSeat[]
  ): { schoolToAdd: SchoolCart; seasonsToAdd: any; ticketsToAdd: TicketCart[] } {
    let activityTitle = '';

    const levelGenderTitle = getLevelGenderTitle(
      season.seasonTypeName ?? '',
      season.levels ?? [],
      season.genders,
      season.optionalTypeDescription
    );

    if (!isEmpty(season.seasonTypeName || season.activity?.name) && !isEmpty(levelGenderTitle)) {
      activityTitle = `${season.seasonTypeName || season.activity?.name} - ${levelGenderTitle}`;
    } else if (!isEmpty(season.seasonTypeName || season.activity?.name)) {
      activityTitle = season.seasonTypeName || season.activity?.name || '';
    } else {
      activityTitle = levelGenderTitle;
    }

    const ticketsToAdd: TicketCart[] = [];
    tickets.forEach(ticket => {
      if (ticket.canBuy && ticket.quantity > 0) {
        const currTicket = ticket.ticketType;
        const newTicket = {
          fee: currTicket.fee,
          id: currTicket.id,
          isEnabled: currTicket.isEnabled,
          name: currTicket.name,
          price: currTicket.price,
          quantity: ticket.quantity,
          packCount: currTicket.packCount,
          updateAt: ticket.updateAt,
          promos: ticket.promos,
          accessCode: ticket.accessCode,
          hiddenFeeBase: currTicket.hiddenFeeBase,
          schoolHuddleId: season.schoolHuddleId || school.id,
          opponentSchoolId: season.opponentSchoolId || '',
          uniqueUrl: ticket.uniqueUrl,
          ticketLimitPerOrder: currTicket.ticketLimitPerOrder
        } as TicketCart;
        if (currTicket.isReservedSeating) {
          const seats: SeatsInfo[] = [];
          reservedSeats?.forEach(seat => {
            if (seat.renewal) {
              if (ticketTypeMatchesSelectedSeat(currTicket, seat)) {
                seats.push({
                  label: seat.id,
                  accessible: false,
                  renewal: seat.renewal,
                  renewalInfo: seat.renewalInfo
                });
              }
            } else if (ticketTypeMatchesSelectedSeat(currTicket, seat)) {
              seats.push({ label: seat.id, accessible: seat.selectedSeat.accessible, renewal: seat.renewal });
            }
          });
          newTicket.seatsInfo = seats;
        }
        ticketsToAdd.push(newTicket);
      }
    });

    const { day, date, time } = this.parseSchedule(
      season.endDateTime,
      season.startDateTime,
      false,
      false,
      EventTypeEnum.SEASON
    );

    const seasonsToAdd: SeasonCart = {
      activity: season.activity,
      id: season.id,
      eventIds: season.eventIds || [''],
      name: season.title,

      day: day,
      date: date,
      time: time,
      timeZone: season.timeZone,
      isSameDay: false,
      isAllDayEvent: false,

      type: EventTypeEnum.SEASON,
      schoolsTicket: [],
      startDateTime: season.startDateTime,
      endDateTime: season.endDateTime,
      ticketTypes: season.ticketTypes,
      eventSalesInfo: season.salesInfo,
      ticketLimitPerOrder: season.ticketLimitPerOrder,
      typeDesciption: activityTitle,
      levels: season.levels,

      isSeason: true,

      tickets: ticketsToAdd,
      updateAt: Date.now(),

      financialSchoolID: season.financialSchoolID,
      financialSchoolIndustryCode: season.financialSchoolIndustryCode,
      financialSchoolName: season.financialSchoolName,
      financialSchoolState: season.financialSchoolState,
      financialSchoolType: season.financialSchoolType,
      financialSchoolPublicPrivate: season.financialSchoolPublicPrivate,
      zipCode: season?.venue?.zip || school.zipCode || season?.financialSchoolZipCode,
      schoolZipCode: school.zipCode,
      financialSchoolZipCode: season?.financialSchoolZipCode
    } as SeasonCart;

    const schoolToAdd: SchoolCart = {
      id: school.huddleId,
      imgSrc: school.logoUrl ? `${school.logoUrl}` : DEFAULT_URL,
      name: school.customPageName || school.name,
      gofanPageEnabled: school.gofanPageEnabled || false,

      events: [seasonsToAdd],
      gofanSchoolType: school.gofanSchoolType
    };
    return { schoolToAdd, ticketsToAdd, seasonsToAdd };
  }

  private getVenue(eventType: EventType, venue?: string) {
    return eventType === EventTypeEnum.TICKET ? venue : '';
  }

  private getEventTypeFromProductType(event: Event): EventType | undefined {
    const { productType } = event.ticketTypes[0];
    switch (productType) {
      case ProductType.MOBILEPASS:
        return EventTypeEnum.MOBILEPASS;

      case ProductType.TICKET:
        return EventTypeEnum.TICKET;

      default:
        if (getProductTypeMeta(productType, 'isTicketLike')) {
          return EventTypeEnum.TICKET;
        } else {
          return undefined;
        }
    }
  }

  private hasSchool(schoolID: string): boolean {
    return this.cartSchools.map(school => school.id).includes(schoolID);
  }

  private hasEvent(matchedSchool: SchoolCart, eventID: string): boolean {
    return matchedSchool.events.map(event => event.id).includes(eventID);
  }

  private hasTicket(matchedEvent: EventCart | SeasonCart, ticketID: string): boolean {
    return matchedEvent.tickets.map(ticket => ticket.id).includes(ticketID);
  }

  private parseSchedule(
    endDateTime: string,
    startDateTime: string,
    isSameDay: boolean,
    isAllDayEvent: boolean,
    eventType: EventType
  ): { day: string; date: string; time: string } {
    let day = '';
    let date = '';
    let time = '';

    if (eventType === EventTypeEnum.TICKET) {
      day = dayjs(startDateTime).format('ddd');
      date = dayjs(startDateTime).format('MMM D');
      time = dayjs(startDateTime).format('h:mmA');
      if (isSameDay && isAllDayEvent) {
        time = 'All Day';
      } else if (!isSameDay && isAllDayEvent) {
        time = 'Multiday';
      }
    } else if (eventType === EventTypeEnum.MOBILEPASS || eventType === EventTypeEnum.SEASON) {
      date =
        dayjs(startDateTime).format('MMM D' + ', ' + dayjs(startDateTime).format('YYYY')) +
        ' - ' +
        dayjs(endDateTime).format('MMM D' + ', ' + dayjs(endDateTime).format('YYYY'));
    }

    return { day, date, time };
  }

  private sortEvents(): void {
    this.cartSchools.forEach(cartSchool => {
      cartSchool.events.sort((eventA: EventCart | SeasonCart, eventB: EventCart | SeasonCart) => {
        if (eventA.startDateTime > eventB.startDateTime) {
          return 1;
        } else if (eventA.startDateTime < eventB.startDateTime) {
          return -1;
        }
        return 0;
      });
    });
  }

  private sortSchoolsAndEvents(): void {
    this.sortEvents();
    this.cartSchools.sort((schoolA: SchoolCart, schoolB: SchoolCart) => {
      if (schoolA.events[0].startDateTime > schoolB.events[0].startDateTime) {
        return 1;
      } else if (schoolA.events[0].startDateTime < schoolB.events[0].startDateTime) {
        return -1;
      }
      return 0;
    });
  }

  static getTicketsState = (tickets: TicketCart[]) => {
    const totalQuantity = tickets.reduce((total, { quantity, packCount }) => {
      const ticketsPerPack = packCount ? packCount : 1;
      return total + quantity * ticketsPerPack;
    }, 0);
    return {
      totalQuantity
    };
  };

  static getTicketDiscounts = (associatedTickets: TicketCart[], offeredPromo: Promotion) => {
    const highToLowPriceTickets = associatedTickets.sort((a, b) => b.price - a.price);
    const { quantityLocked: offeredDiscountQty } = offeredPromo;
    const { value: discountValue, discountType } = offeredPromo._embedded.promotion;
    const initTicketDiscounts = {
      totalDiscountQty: 0,
      tickets: new Map<string, { totalDiscount: number; discountQuantity: number; discountPerTicket: number }>()
    };

    return highToLowPriceTickets.reduce(({ tickets, totalDiscountQty }, { id, packCount, quantity, price, fee }) => {
      const remainingDiscountQty = offeredDiscountQty - totalDiscountQty;

      const ticketsPerPack = packCount ? packCount : 1;
      const ticketsQty = ticketsPerPack * quantity;
      const pricePerTicket = price / ticketsPerPack;

      const discountableQty =
        remainingDiscountQty > ticketsQty ? ticketsQty : remainingDiscountQty - (remainingDiscountQty % ticketsPerPack);

      let discountableValue = 0;
      if (discountType === DISCOUNT_TYPES.PERCENT) {
        const feeBaseDiscount = Number(discountValue) === 100 ? Number(fee) : 0;
        discountableValue = (discountValue / 100) * pricePerTicket + feeBaseDiscount / ticketsPerPack;
      } else if (discountType === DISCOUNT_TYPES.AMOUNT) {
        discountableValue = Math.min(discountValue, price) / ticketsPerPack;
      }
      const ticketPrice = ticketsQty * pricePerTicket;
      let totalDiscount = discountableQty * discountableValue;
      if (totalDiscount >= ticketPrice && discountType === DISCOUNT_TYPES.AMOUNT) {
        totalDiscount = totalDiscount + fee * quantity;
      }
      totalDiscount = Math.round(totalDiscount * 100) / 100;
      return {
        tickets: tickets.set(id, {
          totalDiscount,
          discountQuantity: discountableQty,
          discountPerTicket: discountableValue
        }),
        totalDiscountQty: totalDiscountQty + discountableQty
      };
    }, initTicketDiscounts);
  };

  static calculatePromotion = (
    associatedTickets: TicketCart[],
    offerredPromo: Promotion
  ): Map<string, { promotion: TicketCartPromotion }> => {
    const { promoId, id } = offerredPromo;
    const promoCode = offerredPromo._embedded.promotion.code;
    const { tickets: ticketDiscounts } = Cart.getTicketDiscounts(associatedTickets, offerredPromo);
    const ticketPromotions = new Map<string, { promotion: TicketCartPromotion }>();

    ticketDiscounts.forEach(({ totalDiscount, discountQuantity, discountPerTicket }, key) => {
      ticketPromotions.set(key, {
        promotion: {
          id,
          promoId,
          promoCode,
          totalDiscount,
          discountQuantity,
          promotionData: offerredPromo._embedded.promotion,
          discountPerTicket,
          updatedAt: Date.now()
        }
      });
    });
    return ticketPromotions;
  };

  static isTicketExceededLimit = ({ packCount }: TicketCart, limit: number) => {
    if (packCount > 1) {
      const offerablePacks = (limit - (limit % packCount)) / packCount;
      return offerablePacks < 1;
    }
    return limit < 1;
  };
}
