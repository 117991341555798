import { useRef } from 'react';
import { Fan } from '@gf/cross-platform-lib/models';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { delay, Method } from '@gf/cross-platform-lib/utils';
import isEmpty from 'lodash/isEmpty';
import { GetCurrentApplicationConfiguration } from '@gf/cross-platform-lib/utils/config';
import {
  CREATE_GF_USER_ERROR,
  EMAIL_FORMAT_PATTERN,
  NEW_RELIC_ERROR_GROUPS,
  RESET_PASSWORD_ERROR_MESSAGES,
  SOMETHING_WENT_WRONG,
  USER_NOT_FOUND
} from '@gf/cross-platform-lib/constants';
import { useNavigate } from './navigation/index';
import { useGlobalLoadingContext } from '@gf/cross-platform-lib/providers/GlobalLoadingProvider';
import {
  getUserServiceInfo,
  privateSafeFetch,
  safeFetch,
  updateUserInfo,
  resetPassword as resetPasswordAPI
} from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { useTracking } from './tracking';
import { GfUserCredential, useFirebase } from '@gf/cross-platform-lib/providers/Firebase';
import { resetAllInstances } from '../providers/BusinessProvider';
import { setUserId } from '../utils/newrelic';
import { useChatBot } from './useChatBot';
import { useAppStripeNativeContext } from '../providers/StripeNativeProvider';
import { useMediaQuery } from './useMediaQuery';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers/LaunchDarkly';
import uniq from 'lodash/uniq';

const appConfig = GetCurrentApplicationConfiguration();

export type SignInResult = {
  loggedIn: boolean;
  fan: Fan | undefined;
  error: string | undefined;
};
export type SignInCompletion = (result: SignInResult) => void;
export type SignIn = (email: string, password: string, completion: SignInCompletion, showGlobal?: boolean) => void;

export type SignOut = () => void;

export type SignUpResult = {
  loggedIn: boolean;
  fan: Fan | undefined;
  error: string | undefined;
};

export type ResetPasswordResult = {
  isSuccess: boolean;
  errorMessage?: string;
};
export type SignUpCompletion = (result: SignUpResult) => void;
export type SignUp = (email: string, password: string, completion: SignUpCompletion) => void;
export type CreatePassword = (email: string, password: string, completion: SignUpCompletion) => void;

export type ResetPasswordCompletion = (result: ResetPasswordResult) => void;
export type ResetPassword = (email: string, completion: ResetPasswordCompletion) => void;

export type EmailAlreadyRegisteredResult = { error: string | undefined };
export type EmailAlreadyRegisteredCompletion = (result: EmailAlreadyRegisteredResult) => void;
export type EmailAlreadyRegistered = (email: string, completion: EmailAlreadyRegisteredCompletion) => void;

export type ValidationResult = {
  isValid: boolean;
  emailError: string | undefined;
  passwordError: string | undefined;
};
export type Validate = (email: string, password: string) => ValidationResult;

export type ChangePasswordResult = {
  success: boolean;
  error: string | undefined;
};
export type ChangePassword = (newPassword: string) => Promise<boolean>;

export type BooleanFn = () => boolean;

export type UseAuthenticationResult = {
  validate: Validate;
  signUp: SignUp;
  signIn: SignIn;
  signOut: (shouldRedirectToHome?: boolean) => Promise<void>;
  isFanSignedIn: BooleanFn;
  resetPassword: ResetPassword;
  changePassword: ChangePassword;
  createPassword: CreatePassword;
  emailAlreadyRegistered: EmailAlreadyRegistered;
  isEmailAlreadyRegistered: (email: string) => Promise<boolean>;
  isPasswordExisted: (password: string) => Promise<boolean>;
};

type IsRegisteredCache = {
  [email: string]: boolean;
};

const MAX_CREATE_USER_RETRY = 4;

export const useAuthentication = (): UseAuthenticationResult => {
  const launchDarklyContext = useLaunchDarklyContext();
  const userServiceFlag = launchDarklyContext.features['userService-enabled'].variation as boolean;
  const { variation: launchDarklyResetPasswordEnabled } =
    launchDarklyContext.features['reset-password-using-user-service'];

  const fan = Fan.getInstance();
  const { identifySignIn, identifySignUp, resetTracking, identifySignOut, identifyForgotPassword } = useTracking();
  const firebase = useFirebase();
  const { appAuth } = firebase;
  const {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    fetchSignInMethodsForEmail,
    signOut: appAuthSignOut,
    updatePassword,
    reauthenticateWithCredential
  } = appAuth;
  const { setChatPayload } = useChatBot();
  const { resetPaymentSheetCustomer } = useAppStripeNativeContext();
  const { isNativeApp } = useMediaQuery();
  const errorMessages = {
    lengthRequirement: 'Password must be at least 8 characters long.'
  };

  const validate: Validate = (email: string, password: string) => {
    let emailError = '';
    let passwordError = '';
    const isValidEmailFormat = EMAIL_FORMAT_PATTERN.test(email);
    let passwordValidLength = false;
    let passwordValidComplexity = false;
    //TODO: add these messages into constants folder
    if (!isValidEmailFormat) {
      emailError = email ? 'Enter a valid email address.' : 'Email address is required.';
    }

    if (!passwordValidLength && !passwordValidComplexity) {
      if (isEmpty(password)) {
        passwordError = 'Password is required.';
        passwordValidLength = false;
      } else if (password.length < 8) {
        passwordError = errorMessages.lengthRequirement;
      } else {
        passwordError = '';
        passwordValidLength = true;
      }
      if (passwordValidLength && !passwordValidComplexity) {
        if (password.match(/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/)) {
          passwordError = '';
          passwordValidComplexity = true;
        } else {
          passwordError = 'Password does not meet complexity requirements.';
          passwordValidComplexity = false;
        }
      }
    } else {
      passwordError = '';
    }

    return {
      isValid: isEmpty(emailError) && isEmpty(passwordError),
      emailError,
      passwordError
    };
  };

  const { showGlobalLoading } = useGlobalLoadingContext();
  const signIn: SignIn = (
    email: string,
    password: string,
    completion: SignInCompletion,
    showGlobal: boolean = true
  ) => {
    if (showGlobal) {
      showGlobalLoading(true, { isOverlayFixed: true });
    }
    signInWithEmailAndPassword(email, password)
      .then((userCredential: GfUserCredential | undefined) => {
        if (!userCredential) {
          throw new Error('No user credential');
        }

        setUserId(userCredential.user.uid);

        fan.email = email.toLowerCase();
        fan.firebaseUser = userCredential.user;

        userServiceFlag &&
          getUserServiceInfo(fan.email).then(async ({ data: response }) => {
            if (response) {
              for (let index = 0; index < response.favoriteAccountIds.length; index++) {
                await fan.addFavoriteSchoolById(response.favoriteAccountIds[index]);
              }
              fan.phoneNumber = response.phoneNumber;
              fan.favoriteTeamIds = uniq([...fan.favoriteTeamIds, ...response.favoriteTeamIds]);
              fan.firstName = response.firstName;
              fan.lastName = response.lastName;
            }
            await fan.save();
            showGlobalLoading(false);
            await identifySignIn(fan);
            try {
              setChatPayload({ username: fan.email });
            } catch (error: any) {
              recordError(error, {
                customMessage: `Failed to set chat payload after sign-in`,
                originatingFunction: 'useAuthentication-signIn-setChatPayload',
                data: { email },
                errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
              });
            }
            completion({ loggedIn: true, fan, error: undefined });
          });

        !userServiceFlag &&
          appConfig.api.users.getUrlForMethodAndId &&
          privateSafeFetch<{ favoriteAccountIds: string[]; phoneNumber: string }>(
            appConfig.api.users.getUrlForMethodAndId!(Method.GET, fan.email) || '',
            {
              method: 'GET'
            }
          )
            .then(async ({ data: response }) => {
              if (response) {
                for (let index = 0; index < response.favoriteAccountIds.length; index++) {
                  await fan.addFavoriteSchoolById(response.favoriteAccountIds[index]);
                }
                fan.phoneNumber = response.phoneNumber;
              }

              await fan.save();
              showGlobalLoading(false);
              await identifySignIn(fan);
              try {
                setChatPayload({ username: fan.email });
              } catch (error: any) {
                recordError(error, {
                  customMessage: `Failed to set chat payload after sign-in`,
                  originatingFunction: 'useAuthentication-signIn-setChatPayload',
                  data: { email },
                  errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
                });
              }
              completion({ loggedIn: true, fan, error: undefined });
            })
            .catch(error => {
              console.error('Error:', error);
              showGlobalLoading(false);
              recordError(error, {
                customMessage: `Failed to fetch additional user details after sign-in`,
                originatingFunction: 'signIn-signInWithEmailAndPassword',
                data: { email },
                errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
              });
              completion({ loggedIn: false, fan, error: error?.code || USER_NOT_FOUND });
            });
      })
      .catch((error: any) => {
        showGlobalLoading(false);
        recordError(error, {
          customMessage: `Failed to sign in`,
          originatingFunction: 'signIn-signInWithEmailAndPassword',
          data: { email },
          errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
        });
        completion({ loggedIn: false, fan, error: error?.code });
      });
  };
  const navigate = useNavigate();
  const signOut = async (shouldRedirectToHome: boolean = true) => {
    try {
      await appAuthSignOut().then(async () => {
        identifySignOut(fan);
        await resetAllInstances();
        resetTracking();
        if (isNativeApp) {
          resetPaymentSheetCustomer();
        }
        shouldRedirectToHome && navigate('/');
      });
    } catch (error: any) {
      recordError(error, {
        customMessage: `Sign-out operation failed.`,
        originatingFunction: 'useAuthentication-signOut',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase,
        data: { email: fan.email, shouldRedirectToHome }
      });
      identifySignOut(fan);
      await resetAllInstances();
      resetTracking();
      shouldRedirectToHome && navigate('/');
    }
  };

  const createUser = async (
    { email, password }: { email: string; password: string },
    completion: SignUpCompletion,
    retry: number = 0
  ): Promise<void> => {
    try {
      let signUpHeaders = new Headers({
        'Content-Type': 'application/json'
      });
      let signUpBody = {
        email: `${email}`,
        status: 'ENABLED',
        role: 'FAN'
      };
      const url = appConfig.api.users.getUrlForMethodAndId!(Method.POST, email);

      if (!url) {
        throw new Error(`URL not found for creating user ${email}`);
      }
      await safeFetch(url!, {
        method: 'POST',
        headers: signUpHeaders,
        body: JSON.stringify(signUpBody)
      }).then(() => {
        signIn(email, password, completion);
      });
    } catch (error: any) {
      if (retry < MAX_CREATE_USER_RETRY) {
        await delay(1000 * (retry || 1));
        return await createUser({ email, password }, completion, retry + 1);
      }
      const message = typeof error === 'string' ? error : error.message || 'Could not find error message';
      const recordErrorMsg = `Firebase user ${email} created, but could not create user in DB. Got message: ${message}.`;
      recordError(error, {
        originatingFunction: 'useAuthentication-createUser',
        customMessage: `Firebase user ${email} created, but could not create user in DB.`,
        data: {
          email,
          retry
        },
        errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
      });
      throw { code: CREATE_GF_USER_ERROR, message: recordErrorMsg };
    }
  };

  const signUp: SignUp = (email: string, password: string, completion: SignUpCompletion) => {
    createUserWithEmailAndPassword(email, password)
      .then((userCredential: GfUserCredential | undefined) => {
        if (!userCredential) {
          throw new Error(`No user credential for ${fan.email}`);
        }
        fan.email = email.toLowerCase();
        fan.firebaseUser = userCredential.user;
        identifySignUp(fan);
        return createUser({ email, password }, completion, 0);
      })
      .catch((error: any) => {
        recordError(error, {
          originatingFunction: 'useAuthentication-signUp',
          customMessage: 'Failed to create Firebase or DB user during sign-up process',
          data: {
            email,
            password
          },
          errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
        });
        completion({ loggedIn: false, fan, error: error?.code || SOMETHING_WENT_WRONG });
      });
  };

  const createPassword: CreatePassword = (email: string, password: string, completion: SignUpCompletion) => {
    createUserWithEmailAndPassword(email, password)
      .then((userCredential: GfUserCredential | undefined) => {
        if (!userCredential) {
          throw new Error(`No user credential for ${fan.email}`);
        }
        fan.email = email.toLowerCase();
        fan.firebaseUser = userCredential.user;
        identifySignUp(fan);
        const userBody = {
          email: email,
          status: 'ENABLED',
          acceptedTerms: true,
          optOutCommercialCorrespondence: false,
          optOutPiiSharing: false
        };

        appConfig.api.users.getUrlForMethodAndId &&
          updateUserInfo(email, userBody)
            .then(response => {
              if (response.data === null) {
                completion({ loggedIn: false, fan, error: response?.error?.code.toString() || USER_NOT_FOUND });
              } else {
                signIn(email, password, completion);
              }
            })
            .catch(error => {
              recordError(error, {
                originatingFunction: 'useAuthentication-createPassword-updateUserInfo',
                customMessage:
                  'Failed to update user info while creating a password. User may be left in an inconsistent state.',
                data: { email, userBody },
                errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
              });
            });
      })
      .catch((error: any) => {
        recordError(error, {
          originatingFunction: 'createPassword-createUserWithEmailAndPassword',
          customMessage:
            'Error occurred while creating Firebase user credentials or updating the user in the database. Authentication failed.',
          data: { email, password },
          errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
        });
        completion({ loggedIn: false, fan, error: error?.code || SOMETHING_WENT_WRONG });
      });
  };

  const resetPassword: ResetPassword = (email: string, completion: ResetPasswordCompletion) => {
    if (launchDarklyResetPasswordEnabled) {
      resetPasswordAPI(email.toLowerCase())
        .then(res => {
          // Password reset email sent!
          identifyForgotPassword(email.toLowerCase());
          completion(
            !isEmpty(res.data?.messageId)
              ? { isSuccess: true }
              : {
                  isSuccess: false,
                  errorMessage: RESET_PASSWORD_ERROR_MESSAGES.SERVER_ERROR
                }
          );
        })
        .catch((error: any) => {
          recordError(error, {
            customMessage: 'Failed to send password reset email, leaving user unable to reset password.',
            originatingFunction: 'useAuthentication-resetPassword-sendPasswordResetEmail',
            data: { email },
            errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
          });
          completion({
            isSuccess: false,
            errorMessage: RESET_PASSWORD_ERROR_MESSAGES.LOCAL_ERROR
          });
        });
    } else {
      sendPasswordResetEmail(email.toLowerCase())
        .then(() => {
          // Password reset email sent!
          identifyForgotPassword(email.toLowerCase());
          completion({ isSuccess: true });
        })
        .catch((error: any) => {
          recordError(error, {
            customMessage: 'Failed to send password reset email, leaving user unable to reset password.',
            originatingFunction: 'useAuthentication-resetPassword-sendPasswordResetEmail',
            data: { email },
            errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
          });
          completion({
            isSuccess: false,
            errorMessage: RESET_PASSWORD_ERROR_MESSAGES.LOCAL_ERROR
          });
        });
    }
  };

  const changePassword = (password: string) => {
    showGlobalLoading(true);
    return updatePassword(password)
      .then(() => true)
      .catch(error => {
        recordError(error, {
          customMessage: 'Failed to update password.',
          originatingFunction: 'useAuthentication-changePassword-updatePassword',
          errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase
        });
        return false;
      })
      .finally(() => showGlobalLoading(false));
  };

  const isPasswordExisted = (oldPassword: string) => {
    return reauthenticateWithCredential(oldPassword)
      .then(() => true)
      .catch((error: any) => {
        recordError(error, {
          customMessage: `Failed reauthentication while checking if old password existed.`,
          errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase,
          originatingFunction: 'useAuthentication-isPasswordExisted-reauthenticateWithCredential'
        });
        return false;
      });
  };

  const isRegisteredCache = useRef<IsRegisteredCache>({});
  const isEmailAlreadyRegistered = async (email: string) => {
    if (isRegisteredCache.current[email.toLowerCase()] !== undefined) {
      return isRegisteredCache.current[email];
    }
    return fetchSignInMethodsForEmail(email.toLowerCase()).then((result: any) => {
      if (result[0] === undefined) {
        // no email exists
        isRegisteredCache.current[email.toLowerCase()] = false;
      } else {
        isRegisteredCache.current[email.toLowerCase()] = true;
      }
      return isRegisteredCache.current[email];
    });
  };

  const emailAlreadyRegistered: EmailAlreadyRegistered = (
    email: string,
    completion: EmailAlreadyRegisteredCompletion
  ) => {
    fetchSignInMethodsForEmail(email.toLowerCase())
      .then((result: string[]) => {
        //checks if email is being used
        if (result.length === 0) {
          // no email exists
          completion({ error: 'auth/email-not-in-use' });
        } else {
          completion({ error: 'auth/email-already-in-use' });
        }
      })
      .catch((error: any) => {
        recordError(error, {
          customMessage: `Failed to fetch sign-in methods for the email while checking if email already registered.`,
          originatingFunction: 'useAuthentication-emailAlreadyRegistered-fetchSignInMethodsForEmail',
          errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase,
          data: { email }
        });
      });
  };

  const isFanSignedIn = () => {
    return !!fan.firebaseUser && !!fan.email;
  };

  return {
    validate,
    signIn,
    signOut,
    signUp,
    resetPassword,
    changePassword,
    createPassword,
    isPasswordExisted,
    emailAlreadyRegistered,
    isFanSignedIn,
    isEmailAlreadyRegistered
  };
};
